import { useState } from 'react'
import Logo from '../../assets/logo.svg'

const Menu = () => {
  const [menu, setMenu] = useState(false)
  return (
    <div className='fixed w-full shadow-md bg-background-dark'>
      <nav className='mx-auto container   py-5 '>
        <div className='  flex items-center justify-between w-full'>
          <div className=' flex  items-center'>
            <img
              className='block lg:hidden h-logoMovil w-auto'
              src={Logo}
              alt='Spacepolux Logo'
            />
            <img
              className='hidden lg:block w-52'
              src={Logo}
              alt='Spacepolux Logo'
            />
          </div>

          <div className='hidden sm:block sm:ml-6'>
            <div className='flex space-x-4 items-center'>
              <a
                href='https://www.spacepolux.com'
                rel='canonical'
                className='text-spacetext font-bold text-white px-3 py-2 rounded-md text-sm font-medium'
                aria-current='page'
              >
                Home
              </a>

              <a
                href='#services'
                className='text-spacetext hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium'
              >
                Services
              </a>

              <a
                href='#projects'
                className='text-spacetext hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium'
              >
                Projects
              </a>

              <a
                href='#about'
                className='text-spacetext hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium'
              >
                About Us
              </a>
              <a href='mailto:admin@spacepolux.com' className='bg-background  text-center box-border   text-sm   font-bold rounded-full py-4 px-12'>CONTACT</a>

            </div>
          </div>
          <div className=' inset-y-0 left-0 flex  items-center sm:hidden'>
            <button
              type='button'
              onClick={() => setMenu(!menu)}
              className='inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'
              aria-controls='mobile-menu'
              aria-expanded='false'
            >
              <span className='sr-only'>Open main menu</span>

              <svg
                className='block h-6 w-6'
                xmlns='http://www.w3.org/2000/svg'
                fill='#ffffff'
                viewBox='0 0 24 24'
                stroke='#ffffff'
                aria-hidden='true'
              >
                <path
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='2'
                  d='M4 6h16M4 12h16M4 18h16'
                />
              </svg>

              <svg
                className='hidden h-6 w-6'
                xmlns='http://www.w3.org/2000/svg'
                fill='#ffffff'
                viewBox='0 0 24 24'
                stroke='currentColor'
                aria-hidden='true'
              >
                <path
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='2'
                  d='M6 18L18 6M6 6l12 12'
                />
              </svg>
            </button>
          </div>
        </div>

        <div
          className='hidden'
          style={{ display: menu ? 'block' : 'none' }}
          id='mobile-menu'
        >
          <div className='px-2 pt-2 pb-3 space-y-1'>
            <a
              href='https://www.spacepolux.com'
              rel='canonical'
              className='text-spacetext text-white block px-3 py-2 rounded-md text-base font-medium'
              aria-current='page'
            >
              Home
            </a>

            <a
              href='#services'
              className='text-spacetext hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium'
            >
              Services
            </a>

            <a
              href='#projects'
              className='text-spacetext hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium'
            >
              Projects
            </a>

            <a
              href='#about'
              className='text-spacetext hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium'
            >
              About Us
            </a>
          </div>
        </div>

      </nav>
    </div>
  )
}

export default Menu
