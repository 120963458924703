import Header from '../assets/header.svg'
import Code from '../assets/icons/code.svg'
import Crypto from '../assets/icons/crypto.svg'
import Phone from '../assets/icons/phone.svg'
import Space from '../assets/icons/space.svg'
import Banner from '../assets/banner.svg'
import Spacefolio from '../assets/spacefolio.png'
import PlayStore from '../assets/playstore.png'
import AppStore from '../assets/appstore.png'
import Arrow from '../assets/icons/arrow.svg'
import About from '../assets/about.svg'
import ArrowLeft from '../assets/icons/arrowRight.svg'
import Spaceland from '../assets/spaceland.png'
const Home = () => {
  return (
    <>
      <header id='home' className='container mx-auto pt-36'>
        <div className='flex flex-wrap-reverse md:flex-nowrap h-header items-center'>
          <div className=' flex  flex-col space-y-14 content-evenly'>
            <h1 className='text-3xl md:text-5xl sm:my-20 text-center md:text-left  font-semibold text-spacetext'>
              Develop your future with us,{' '}
              <span className='text-spacetext-secondary'>in record time. </span>.
            </h1>
            <a
              href='mailto:admin@spacepolux.com'
              className='bg-background mt-3 text-center   md:w-56  text-md mx-auto md:mx-0  font-bold rounded-full py-4 px-12'
            >
              LET’S TALK
            </a>
          </div>

          <img className='w-full md:w-2/4' src={Header} alt='Spacepolux ' />
        </div>
      </header>
      <img src={Arrow} className='mx-auto  mb-20' width='30px' />
      <section id='services' className='container mx-auto my-30'>
        <div className='grid sm:grid-cols-1 md:grid-cols-2 gap-40 '>
          <div className='my-auto'>
            <h1 className='text-3xl md:text-5xl text-center md:text-left  sm:my-20  font-semibold text-spacetext'>
            The moon is our home <br/>
              <span className='text-spacetext-secondary'>Our services take you there. </span> 
            
            </h1>

            <p className='text-spacetext text-xl font-light mt-10 text-center md:text-left'>
            We specialize in enterprise-scale solutions that power the growth of your startup.
            </p>
          </div>
          <div className='grid sm:grid-cols-1 md:grid-cols-2 gap-x-9 gap-y-14 '>
            <div className='flex-col'>
              <img width='90px' className='mx-auto' src={Code} />
              <p className='text-spacetext sm:text-md md:text-xl font-light text-center'>
                Creating transformational new digital products at the
                intersection of market opportunity, customer experience, and
                emerging technology
              </p>
            </div>
            <div className='flex-col'>
              <img width='90px' className='mx-auto' src={Phone} />
              <p className='text-spacetext sm:text-md md:text-xl font-light text-center'>
              Working with Autonomous Delivery Teams gives you all the tools you need to achieve your IT goals in record time.
              
              </p>
            </div>

            <div className='flex-col'>
              <img width='90px' className='mx-auto' src={Crypto} />
              <p className='text-spacetext sm:text-md md:text-xl font-light text-center'>
                The cryptocurrency ecosystem lives in us, we develop your ideas
                efficiently adjusted to the needs of the market
              </p>
            </div>

            <div className='flex-col'>
              <img width='90px' className='mx-auto' src={Space} />
              <p className='text-spacetext sm:text-md md:text-xl font-light text-center'>
                Re-architecting legacy technology for better speed, scale,
                security, and user experience
              </p>
            </div>
          </div>
        </div>
      </section>
      <div
        className='h-mbanner auto md:h-banner flex items-center justify-center my-20'
        style={{
          backgroundImage: `url('${Banner}')`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover'
        }}
      >
        <h1
          className='text-xl md:text-5xl font-semibold text-spacetext'
          style={{ textShadow: '1px 1px 2px black' }}
        >
          THE COMPLETE SOLUTIONS
        </h1>
      </div>
      <section id='projects' className='container mx-auto my-30 '>
        <h1 className='text-3xl md:text-5xl font-semibold text-spacetext text-center my-20'>
          PROJECTS
        </h1>
        <div className='grid sm:grid-cols-1  md:grid-cols-2 gap-20 '>
          <img src={Spacefolio} alt='Spacefolio App' />
          <div className='flex flex-col  justify-center'>
            <h1 className='text-3xl md:text-5xl font-semibold  my-5 text-spacetext text-center md:text-left   '>
              Spacefolio
            </h1>

            <p className='text-spacetext text-xl font-light my-14 text-center md:text-left'>
              The most professional portfolio on the market where you can
              organize your entire cryptocurrency ecosystem in a single app.
            </p>
            <div className='flex justify-center opacity-40  my-5 md:justify-start  '>
              <img
                width='150px'
                className='mr-3 '
                src={PlayStore}
                alt='Spacefolio PlayStore'
              />
              <img width='150px' src={AppStore} alt='Spacefolio Apple Store' />
            </div>
            <a href='https://www.spacefolio.app' target="__blank" className='mt-5 mb-16 flex text-spacetext items-center md:w-52  border-2 text-md mx-auto md:mx-0  font-bold rounded-full py-2 px-8'>
            Learn more   <img className='ml-3' src={ArrowLeft} alt='ArrowLeft' />
            </a>
            <button className='bg-background mt-10   md:w-56  text-md mx-auto md:mx-0  font-bold rounded-full py-4 px-12'>
              COMING SOON
            </button>
          </div>
          
        </div>

        <div className='flex flex-col-reverse md:flex-row my-10'>
          <div className='flex flex-col  justify-center'>
            <h1 className='text-3xl md:text-5xl font-semibold  my-2 text-spacetext text-center md:text-left   '>
            SPACELAND ASTRONAUTS
NFT COLLECTION
            </h1>

            <p className='text-spacetext text-xl font-light  my-12  text-center md:text-left'>
            Spaceland Astronauts are a 10,000 collecion of unique NFTs living in the Ethereum blockchain.
            </p>
            <a href='https://www.spacelandastronauts.com' target="__blank" className='mt-5 mb-16 flex text-spacetext items-center md:w-52  border-2 text-md mx-auto md:mx-0  font-bold rounded-full py-2 px-8'>
            Learn more   <img className='ml-3' src={ArrowLeft} alt='ArrowLeft' />
            </a>
         
            <button className='bg-background mt-3   md:w-64 text-md mx-auto md:mx-0  font-bold rounded-full py-4 px-14'>
              COMING SOON
            </button>
          </div>
          <img src={Spaceland} alt='Spaceland' />
          
        </div>
      </section>

      <section id='about' className='bg-background-blueDark mt-10'>
        <div className='container mx-auto '>
          <div className='flex flex-wrap md:flex-nowrap h-header items-center'>
            <div className=' flex  flex-col space-y-14 content-evenly'>
              <h1 className='text-3xl md:text-5xl sm:my-20 text-center md:text-left  font-semibold text-spacetext'>
                About Us
              </h1>
              <p className='text-spacetext text-xl w-full md:w-3/4 font-light mt-10 text-center md:text-left'>
                The moon is our home, We architect and engineer scalable and
                high-performing software solutions to meet the business
                challenges of our clients.
              </p>
            </div>

            <img
              className='w-full md:w-2/4'
              src={About}
              alt='about spacepolux'
            />
          </div>
        </div>
      </section>
      <section className='bg-background-blue'>
        <div className='container mx-auto p-10'>
          <h1 className='text-3xl md:text-5xl sm:my-20 text-center   font-semibold text-spacetext'>
            Join us and take off in the next spaceship
          </h1>
          <a
            href='mailto:admin@spacepolux.com'
            className='bg-background mt-5   md:w-56  text-md mx-auto block text-center  font-bold rounded-full py-4 px-12'
          >
            GET IT TOUCH
          </a>
        </div>
      </section>
    </>
  )
}

export default Home
